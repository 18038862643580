<template>
  <div class="main">
    <div style="position: fixed; right: 1rem; top: 0.5rem">
      <van-icon
        name="chat-o"
        color="#fff"
        size="1rem"
        style="vertical-align: middle"
      />
    </div>
    <van-row style="background-color: #3185ff">
      <div class="photo">
        <img :src="headImgUrl||require('@/assets/tx.png')" />
        <div class="userName" style="margin-left: 50%;">
          <!-- 左边距20是为了减去logo占用的那一半 -->
          <span style="transform: translateX(-50%);margin-left: 20px;display: flex;flex-direction: row;align-items: center;">
            {{ nickName||'雨落倾城' }}
            <span :style="{fontSize:'10px',color:'#fff',padding:'1px 4px 1px 4px',backgroundColor:realNameAuth == 0?'#e11':realNameAuth == 1?'#1b3':'#fa0',borderRadius:'14px'}">
              {{realNameAuth == 0?'未实名':realNameAuth == 1?'已实名':'无需实名'}}
            </span>
            <!-- <span :style="{marginTop:'-10px',fontSize:'10px',color:'#fff',padding:'1px 4px 1px 4px',backgroundColor:admin?'rgb(110, 72, 4)':realNameAuth == 0?'#e11':realNameAuth == 1?'#1b3':'#fa0',borderRadius:'14px'}">
              {{admin?'肖':realNameAuth == 0?'未实名':realNameAuth == 1?'已实名':'无需实名'}}
            </span> -->
          </span>
          
          <!-- <span style="margin-left: 50%;transform: translateX(-50%);">{{ nickName }}</span>
          <span :style="{fontSize:'10px',color:'#fff',padding:'0px 4px 1px 4px',backgroundColor:realNameAuth == 0?'#e11':realNameAuth == 1?'#1b3':'#fa0',borderRadius:'14px',transform:realNameAuth == 0?'translateX(-50%)':'translateX(-70%)'}">
            {{realNameAuth == 0?'未实名':realNameAuth == 1?'已实名':'无需实名'}}
          </span> -->
        </div>
      </div>
    </van-row>
    <van-row class="middle">
      <van-col span="8" @click="toUrl('used')">
          <span>{{ nowMonth }}月</span><br />
          <span>套餐</span>
      </van-col>
      <!-- <van-col span="6">
        <span>{{ balance }}元</span><br />
        <span>剩余余额</span>
      </van-col> -->
      <van-col span="8" @click="toUrl('voiceAdd')">
        <span>{{ residueVoice }}</span
        ><br />
        <span>剩余语音</span>
      </van-col>
      <van-col span="8" @click="toUrl('flowAdd')">
        <span>{{ residueFlow }}</span
        ><br />
        <span>剩余流量</span>
      </van-col>
    </van-row>

    <van-cell-group style="margin-top: 5px; padding-left: 1rem">
      <van-cell is-link :url="'/whitenum'+wUrl" v-if="cardCorp == 1">
        <template #title>
          <span
            ><van-icon
              name="records"
              color="#f26b4b"
              size="1rem"
              style="vertical-align: middle"
          /></span>
          <span class="custom-title" style="margin-left: 0.4rem"
            >白名单设置</span>
        </template>
      </van-cell>
      <van-cell
        @click="toReal"
        v-if="realNameAuth == 0"
      >
        <template #title>
          <span
            ><van-icon
              name="award-o"
              color="#57d0a7"
              size="1rem"
              style="vertical-align: middle"
          /></span>
          <span class="custom-title" :style="{marginLeft: '0.4rem',color:realNameAuth == 0?'':realNameAuth == 1?'#1b3':'#fa0'}">{{realNameAuth == 0?'去实名':'已实名'}}</span>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group style="margin-top: 5px; padding-left: 1rem">
      <van-cell is-link :url="'/recharge'+wUrl">
        <template #title>
          <span
            ><van-icon
              name="orders-o"
              color="#ffab00"
              size="1rem"
              style="vertical-align: middle"
          /></span>
          <span class="custom-title" style="margin-left: 0.4rem">充值记录</span>
          <!-- <van-badge v-show="tagNum>0" :content="tagNum" /> -->
        </template>
      </van-cell>
      <van-cell v-if="comboType == 1" is-link :url="'/balanceRecord'+wUrl">
        <template #title>
          <span
            ><van-icon
              name="balance-list-o"
              color="#1989fa"
              size="1rem"
              style="vertical-align: middle"
          /></span>
          <span class="custom-title" style="margin-left: 0.4rem">余额记录</span>
        </template>
      </van-cell>
      <van-cell v-if="admin == 1" @click="adminShow">
        <template #title>
          <span
            ><van-icon
              name="balance-list-o"
              color="#1989fa"
              size="1rem"
              style="vertical-align: middle"
          /></span>
          <span class="custom-title" style="margin-left: 0.4rem" @click="refAll">一键清空本地存储</span>
        </template>
      </van-cell>
      <!-- <input id="inputCopy" style="opacity: 0;"/> -->
        
      <!-- <button @click="getPhone" style="opacity: 0">清空</button> -->
      <!-- <van-cell is-link>
        <template #title>
          <span
            ><van-icon
              name="chat-o"
              color="#f4d575"
              size="1rem"
              style="vertical-align: middle"
          /></span>
          <span class="custom-title" style="margin-left: 0.4rem">常见问题</span>
        </template>
      </van-cell> -->
      <!-- <van-cell is-link url="/repair/wxbf67cf4272683724">
        <template #title>
          <span
            ><van-icon
              name="newspaper-o"
              color="#f4d575"
              size="1rem"
              style="vertical-align: middle"
          /></span>
          <span class="custom-title" style="margin-left: 0.4rem">留言板</span>
        </template>
      </van-cell> -->

    </van-cell-group>
    <!-- 实名 -->
    <van-popup
      v-model:show="show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div class="popup" v-show="dialogShow == 1" style="margin-top: 20px;">
        <div class="popupTit">点击跳转</div>
        <div>#小程序://中移物联卡实名登记/SaRg6dKYznto7Kc</div>
        <!-- <van-image
          class="popupImg"
          width="10rem"
          height="10rem"
          fit="contain"
          :src="require('@/assets/lt.png')"
        /> -->
      </div>
      <div class="popup" v-show="dialogShow == 2" style="margin-top: 20px;">
        <div class="popupTit">长按识别</div>
        <van-image
          class="popupImg"
          width="10rem"
          height="10rem"
          fit="contain"
          :src="require('@/assets/lt.png')"
        />
      </div>
    </van-popup>
    <common-footer />
  </div>
</template>

<script>
import { selectCardUsed, realName ,selectOrderCombo, selectList} from "@/api/card";
import { parseTime } from "@/utils/index";
import commonFooter from "@/views/common/footer.vue";
import "@/assets/info.css";
import { calculateFlow } from "@/utils/convert";
import { Toast,Notify} from "vant";
// import clipboard from 'vue-clipboard2'
// import wechatUtil from "@/utils/wechatUtil";
import Cookies from "js-cookie";
// import store from "@/store";
export default {
  name: "info",
  components: {
    commonFooter,
  },
  data() {
    return {
      msisdn: "",
      iccId: "",
      balance: 0,
      residueFlow: "0M",
      residueVoice: "0分",
      nowMonth: parseTime(new Date(), "{m}"),
      headImgUrl: "",
      nickName: "默认",
      show: false,
      dialogShow: 0,
      tagNum:0,
      url:'1234567890',
      cardType:0,
      realNameAuth:0,
      wUrl:null,
      admin:0,
      userInfo:null,
      cardCorp:0,
      comboType:localStorage.getItem('comboType')||0
    };
  },
  watch: {
    show: "ds",
  },
  created() {
    this.wUrl = '/'+localStorage.getItem('resFlog1')
    console.log('wid',this.wUrl)
    // 实名状态
    this.realNameAuth = localStorage.getItem('SET_REAL_NAME_AUTH')
    // this.realNameAuth = 2
    // 是不是支付跳过来实名的
    // this.show = this.$store.getters.isRealName
    // store.commit("SET_IS_REAL_NAME", false);
    
    this.cardType =localStorage.getItem('SET_CARD_TYPE')
    this.cardCorp = Cookies.get('corp')
    this.iccid = Cookies.get('card-id');
    this.getCardInfo();
    this.getCardUsed();
    console.log('=============',this.userInfo,this.$store.getters.userInfo.headimgurl);
    this.headImgUrl = this.$store.getters.userInfo.headimgUrl||this.userInfo.headimgurl;
    this.nickName = this.$store.getters.userInfo.nickname||this.userInfo.nickname;
    selectList(this.iccid).then(res=>{
      console.log(res)
    })
    
    selectOrderCombo(
      this.iccid,
      1,
      this.pageNum,
      this.pageSize
    ).then((res) => {
      console.log(res)
      this.tagNum = res.total
    })



    this.admin =localStorage.getItem('admin')

  },
  methods: {
    refAll(){
      localStorage.clear()
      localStorage.clear()
      Cookies.clear()
      Toast({
        message: "清空",
        position: "bottom",
      });
      
    },
    adminShow(){
      console.log('管理员')
    },
    toReal(){
      // this.show = this.realNameAuth == 0 ?true:false;
      
      this.cardCorp = Cookies.get('corp')
      // 复制ICCID
      this.copyToClip()
      if (this.cardCorp == 1) {
        console.log("中国移动");
        // this.dialogShow = 1;
        // this.show = true
        alert('请前往公众号实名!')
      } else if (this.cardCorp == 2) {
        console.log("中国联通");
        this.dialogShow = 2;
        this.show = true
      } else if (this.cardCorp == 4) {
        console.log("中国电信");
          this.dialogShow = 3;
        setTimeout(() => {
          window.open(
            // "http://uniteapp.ctwing.cn:9090/uapp/certifhtml/certif_entry.html?userCode=nGmFFq6z4WZxGKIgPw+jBw==&passWord=97xjIqkYSeyG6wc5YiqGjA=="
            "https://cmp-miniapps.ctwing.cn/#/pages/ordinaryuserRealName/index"
          );
        },500);

      }








    },
    // 监听，如果实名窗口关闭，那么把联通实名图片隐藏，否则拷贝iccid
    ds(i) {
      if (i != true) {
        this.show = 0;
        setTimeout(() => {
          this.dialogShow = 0;
        }, 300);
      }else{
        this.copyToClip()
      }
    },
    copyToClip(){
      var oInput = document.createElement('input'); //创建一个input
      oInput.setAttribute("readonly", "readonly");//设置只读，否则移动端使用复制功能时可能会造成软件盘弹出
      let iccid = Cookies.get('card-id');
      oInput.value = iccid;
      document.body.appendChild(oInput);//将input插入到body
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.style.display='none'; // 将input隐藏
      oInput.remove(); // 将input销毁
      Toast({
        message: "iccid已复制",
        position: "bottom",
      });
    },
    getPhone(){
      
      localStorage.removeItem('resFlog')
    //   wechatUtil.init(["scanQRCode"]).then((wx) => {
    //     console.log(wx)
    //     wx.scanQRCode({
    //     desc: 'scanQRCode desc',
    //     needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
    //     scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
    //     success: function (res) {
    //       // 回调
    //     },
    //     error: function(res){
    //       if(res.errMsg.indexOf('function_not_exist') > 0){
    //           alert('版本过低请升级')
    //       }
    //     }
    // });
    //   })
    },
    toUrl(rout){
      console.log(rout)
      
      this.$router.push("/"+rout+this.wUrl)
    },
    getCardInfo() {
      let wid = localStorage.getItem('resFlog1')
      this.userInfo = JSON.parse(localStorage.getItem('user-info'))
      let iccid = Cookies.get('card-id');
      this.$store
        .dispatch("getCardInfo", {
          iccid:iccid || this.userInfo.iccid,
          officialId: wid,
        })
        .then((res) => {
          this.msisdn = res.msisdn;
          this.iccId = res.iccid;
          this.balance = res.balance;
        });
    },
    getCardUsed() {
      let iccid = Cookies.get('card-id');
      selectCardUsed(
        iccid,
        // parseTime(new Date(), "{y}{m}")
      ).then((res) => {
        if (res != null) {
          let cardUsed = calculateFlow(res);
          this.residueFlow = cardUsed.residueFlowUnit;
          this.residueVoice = cardUsed.residueVoiceUnit;
          // this.nowMonth = String(res.cycleYm).slice(-2);
          this.nowMonth = (new Date()).getMonth()+1;
        }
      });
    },
  },
};
</script>

<style scoped>
.popup {
  margin-top: 50px;
  width: 100%;
}
.popupItem {
  padding: 12px;
  margin: 2px auto;
  width: 50%;
  text-align: center;
  border-radius: 30px;
  /* border: 1px solid rgb(91, 149, 255); */
  background-color: #3185ff;
  opacity: 0.8;
  color: #fff;
  cursor: pointer;
}
.popupTit {
  margin: 4px auto;
  text-align: center;
}
.popupImg {
  margin-left: 30%;
  border-radius: 30px;
}
.userName{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}
</style>
