<template>
  <div>
    <van-tabbar v-model="tabBarActive" @change="itemChange">
      <van-tabbar-item name="combo" icon="orders-o">充值</van-tabbar-item>
      <van-tabbar-item name="used" icon="bar-chart-o">用量</van-tabbar-item>
      <van-tabbar-item name="info" icon="contact">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      tabBarActive: "combo"
    };
  },
  mounted() {
    this.tabBarActive =
      this.$route.name === "balance" ? "combo" : this.$route.name;
  },
  methods: {
    itemChange(item) {
      
      let appid = localStorage.getItem('resFlog1')
      this.$router.push({
        name: item,
        params: { officialId: appid }
      });
    }
  }
};
</script>

<style scoped></style>
