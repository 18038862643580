export function calculateFlow(response) {
  if(!response){return}
  const cardUsedData = new Object();
  //流量
  let totalFlow = response.totalFlow;
  let usedFlow = response.usedFlow;
  let residueFlow = minus(totalFlow, usedFlow);
  cardUsedData.totalFlow = totalFlow;
  cardUsedData.totalFlowUnit = formatFlow(totalFlow);
  cardUsedData.usedFlow = usedFlow;
  cardUsedData.usedFlowUnit = formatFlow(usedFlow);
  cardUsedData.residueFlow = residueFlow;
  cardUsedData.residueFlowUnit = formatFlow(residueFlow);
  //占用百分比
  let usedFlowPercent = divide(usedFlow, totalFlow)?divide(usedFlow, totalFlow):0;
  let residueFlowPercent = divide(residueFlow, totalFlow)?divide(residueFlow, totalFlow):0;
  cardUsedData.usedFlowPercent = times(usedFlowPercent.toFixed(2), 100);
  cardUsedData.residueFlowPercent = times(residueFlowPercent.toFixed(2), 100);
  //短信
  let totalSms = response.totalSms;
  let usedSms = response.usedSms;
  let residueSms = minus(totalSms, usedSms);
  cardUsedData.usedSms = usedSms;
  cardUsedData.usedSmsUnit = usedSms + "条";
  cardUsedData.totalSms = totalSms;
  cardUsedData.totalSmsUnit = totalSms + "条";
  cardUsedData.residueSms = residueSms;
  cardUsedData.residueSmsUnit = residueSms + "条";
  //语音
  let totalVoice = response.totalVoice;
  let usedVoice = response.usedVoice;
  let residueVoice = minus(totalVoice, usedVoice);
  cardUsedData.usedVoice = usedVoice;
  cardUsedData.usedVoiceUnit = usedVoice + "分";
  cardUsedData.totalVoice = totalVoice;
  cardUsedData.totalVoiceUnit = totalVoice + "分";
  cardUsedData.residueVoice = residueVoice;
  cardUsedData.residueVoiceUnit = residueVoice + "分";
  let usedVoicePercent = divide(usedVoice, totalVoice)?divide(usedVoice, totalVoice):0;
  let residueVoicePercent = divide(residueVoice, totalVoice)?divide(residueVoice, totalVoice):0;
  console.log('百分比',usedVoicePercent,residueVoicePercent)
  cardUsedData.usedVoicePercent = times(usedVoicePercent.toFixed(2), 100);
  cardUsedData.residueVoicePercent = times(residueVoicePercent.toFixed(2), 100);
  cardUsedData.comboTitle=response.comboTitle;
  cardUsedData.expiryDate=response.expiryDate;
  cardUsedData.effectiveDate=response.effectiveDate;
  return cardUsedData;
}

//计算后带单位返回
export function formatFlow(flow) {
  
  //假设大于1024
  if (flow > 1024) {
    let flowData = divide(flow, 1024);
    return flowData.toFixed(2) + "GB";
  }
  // 超额流量情况
  if(flow < -1024){
    let flowData = divide(flow*(-1), 1024);
    return '-'+flowData.toFixed(2) + "GB";
  }
  return flow.toFixed(2) + "M";
}
//浮点数减法
export const minus = (num1, num2) => {
  if((num1||num1 === 0)&& (num2||num2 === 0)){
    const num1Digits = (num1.toString().split(".")[1] || "").length;
    const num2Digits = (num2.toString().split(".")[1] || "").length;
    const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
    return (times(num1, baseNum) - times(num2, baseNum)) / baseNum;
  }
};

//除法
export const divide = (num1, num2) => {
  // console.log('百分比1/',num1, num2)
  if((num1>= 0)&& (num2||num2 >= 0)){
    const num1Digits = (num1.toString().split(".")[1] || "").length;
    const num2Digits = (num2.toString().split(".")[1] || "").length;
    const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
    // console.log('百分比2/',num1Digits,num2Digits,baseNum)
    return times(num1, baseNum) / times(num2, baseNum) / baseNum;
  }
};

//乘法
function times(num1, num2) {
  if((num1||num1 >= 0)&& (num2||num2 >= 0)){
  // console.log('百分比3x',num1, num2)
  const num1String = num1.toString();
  const num2String = num2.toString();
  // 小数点后几位
  const num1Digits = (num1String.split(".")[1] || "").length;
  const num2Digits = (num2String.split(".")[1] || "").length;
  const baseNum = Math.pow(10, num1Digits + num2Digits);
  // console.log('百分比4x',num1String, num2String,num1Digits,num2Digits,baseNum)
  return (
    // (Number(num1String.replace(".", "")?num1String.replace(".", ""):0) *
    (Number(num1String.replace(".", "")) *
      Number(num2String.replace(".", ""))) /
    baseNum
  );
  }
}
